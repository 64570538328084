import { Directive, ElementRef } from '@angular/core';
import { BasePopupService } from './base.popup.service';

export type PopUpFloat = 'left' | 'right' | 'center';

export interface PopUpInput<T> {
  data: T;
  float: PopUpFloat;
  rect: DOMRect;
  z?: number;
}

@Directive({ selector: 'unused' })
export abstract class BasePopUpDirective<InputData, OutputData> {

  abstract float: PopUpFloat;
  abstract z: number;

  protected listener!: () => void;

  constructor(
    protected el: ElementRef,
    protected service: BasePopupService<PopUpInput<InputData>, OutputData>
  ) {}

  protected setData(data: InputData) {
    this.listener = () => this.show(data);
    this.el.nativeElement.addEventListener('click', this.listener);
    console.log('listener set up');
  }

  getPopUpExtra(): Record<string, any> { return  {} };

  async show(data: InputData): Promise<void> {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const currentStyle = this.el.nativeElement.style.cssText;
    // console.log('currentStyle: ', currentStyle);
    this.el.nativeElement.style.cssText = 'opacity: 1;' + currentStyle;
    const response = await this.service.show({
      ...this.getPopUpExtra(),
      rect,
      data,
      float: this.float,
      z: this.z
    });
    this.el.nativeElement.style.cssText = currentStyle;
    if (response) {
      this.onResponse(response);
    }
  }

  ngOnDestroy(): void {
    this.el.nativeElement.removeEventListener('click', this.listener);
  }

  onResponse(data: OutputData): void {}
}
